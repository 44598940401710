<template>
  <div style="width: 100%">
    <div class="container">
      <page-header-image />
      <div style="margin-top: 100px"></div>
      <div class="contentContainer">
        <aside class="slideBar">
          <div
            class="slidebarItem"
            v-for="(item, index) in title"
            :key="item"
            :class="{ active: index == isActive }"
            @click="setActive(index)"
          >
            {{ item }}
            <img
              class="vector"
              :src="vectorImg"
              alt=""
              :class="{ activeVector: index == isActive }"
              @click="setActive(index)"
            />
          </div>
        </aside>
        <main class="content">
          <picture v-for="item in imgList[isActive]" :key="item">
            <img :src="item" alt="" class="picture" />
          </picture>
        </main>
      </div>
    </div>
  </div>
</template>
<script>
import PageHeaderImage from "../components/PageHeaderImage.vue";

export default {
  components: { PageHeaderImage },
  data() {
    return {
      isActive: 0,
      vectorImg: require("@/static/img/Vector.png"),
      title: ["精益小单元制造", "高效的管理团队", "高精密的生产设备"], // require("@/static/img/advantageDetail")
      imgList: [
        [
          require("@/static/img/advantageDetail/1/1.png"),
          require("@/static/img/advantageDetail/1/2.png"),
        ],
        [require("@/static/img/advantageDetail/3/1.png")],
        [
          require("@/static/img/advantageDetail/2/1.png"),
          require("@/static/img/advantageDetail/2/2.png"),
          require("@/static/img/advantageDetail/2/3.png"),
          require("@/static/img/advantageDetail/2/4.png"),
          require("@/static/img/advantageDetail/2/5.png"),
        ],
      ],
    };
  },
  created() {
      this.isActive = this.$route.params.index;
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0
  },
  methods: {
    setActive(data) {
      this.isActive = data;
    },
  },
};
</script>

<style scoped>
* {
  padding: 0;
  box-sizing: border-box;
  margin: 0;
}

.contentContainer {
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-column-gap: 70px;
  position: relative;
  left: 5%;
  width: 90%;
}

.slideBar {
  position: relative;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 476.25px;
  width: 100%;
}

.slidebarItem {
  position: relative;
  width: 100%;
  height: 80px;
  background: #1f89eb;
  margin-top: 10px;
  font-family: PingFang SC;
  font-style: normal;
  font-size: 32px;
  line-height: 80px;
  color: #ffffff;
  padding-left: 30px;
  text-align: start;
  transition: all 0.5s;
  cursor: pointer;
}

.active {
  background: #041d72;
  transition: all 0.5s;
  height: 206.25px;
}

.vector {
  position: absolute;
  right: 30px;
  top: 24px;
  height: 32px;
  transition: all 0.5s;
}

.activeVector {
  transform: rotate(90deg);
}

.content {
  position: relative;
  width: 100%;
  transition: all 0.5s;
}

.picture{
  display: block;
  margin: auto;
  width: 75%;
  margin-bottom: 50px;
}

h2 {
  display: inline-block;
  width: 100%;
  text-align: center;
  margin-bottom: 75px;
}

.detailContainer {
  width: inherit;
  display: none;
  font-family: PingFang SC;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 25px;
  text-align: justify;
  color: #0e0e0e;
  opacity: 0;
  transition: all 0.5s;
  margin-top: 10px;
}

.activeContent {
  opacity: 1;
  transition: all 0.5s;
  display: inline-block;
}

@media screen and (max-width: 1450px) {
  .contentContainer {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr auto;
  }

  .slideBar {
    position: relative;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    height: 80px;
    width: 100%;
    margin-bottom: 30px;
  }

  .slidebarItem {
    margin-top: 0;
    margin-right: 10px;
    padding: 0;
    text-align: center;
  }

  .vector {
    display: none;
  }

  .active {
    background: #041d72;
    transition: all 0.5s;
    height: 80px;
  }
}

@media screen and (max-width: 750px) {
  .slidebarItem {
    font-size: 16px;
    height: 60px;
    line-height: 60px;
  }

  .slideBar {
    height: 60px;
    margin-bottom: 15px;
  }
}
</style>
